import React from 'react'
// Import Swiper React components
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';


// Import Swiper styles

import 'swiper/modules/autoplay/autoplay.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/scrollbar/scrollbar.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

import './swiper.scss'; // core Swiper

export default () => {


  let names = ['PORR',
            'ERBUD',
            'TAKENAKA',
            'BUDIMEX',
            'STRABAG',
            'MIRBUD',
            'SKANSKA',
            'MOSTY ŁÓDŹ',
            'ADAMIETZ',
            'DORACO',
            'ATLAS WARD',
            'WARBUD ',
            'SHELLTER',
            'HILL',
            'SPEC BAU',
            'AMW SINEVIA',
            'DALDEHOG',
            'CHEMOBUDOWA',
            'TLC INWEST',
            'ART BUILDING',
            'KRAJEWSKI KONSTRUKCJE',
            'AWM BUDOWNICTWO',
            'FRESENIUS KABI',
            'VOLVO',
            'KNOLL',
            'UTC AEROSPACE',
            'S&I-GS ',
            'FRONTIER',
            'MCS WROCŁAW',
            'HOSPICJUM DLA DZIECI',
            'M&J ',
            'RS BAU',
            'MAXBUD',
            'SAVI ',
            'PROBITAS',
            'CZART',
            'INCEPTI',
            'MPK WROCŁAW']





  let photos = ['/img/logo-new/1_porr.jpg',
                '/img/logo-new/2_erbud.jpg',
                '/img/logo-new/3_takenaka.jpg',
                '/img/logo-new/4_budimex.jpg',
                '/img/logo-new/5_strabag.jpg',
                '/img/logo-new/6_mirbud.jpg',
                '/img/logo-new/7_skanska.jpg',
                '/img/logo-new/8_mosty-lodz.jpg',
                '/img/logo-new/9_adamiedz.jpg',
                '/img/logo-new/10_doraco.jpg',
                '/img/logo-new/11_atlas-ward.jpg',
                '/img/logo-new/12_warbud.jpg',
                '/img/logo-new/13_shelter.jpg',
                '/img/logo-new/14_hill.jpg',
                '/img/logo-new/15_spec_bau.jpg',
                '/img/logo-new/16_amv.jpg',
                '/img/logo-new/17_daldehog.jpg',
                '/img/logo-new/18_chemobudowa.jpg',
                '/img/logo-new/19_tlc_invest.jpg',
                '/img/logo-new/20_art-building.jpg',
                '/img/logo-new/21_krajewski.jpg',
                '/img/logo-new/22_amw.jpg',
                '/img/logo-new/23_fresenius.jpg',
                '/img/logo-new/24_volvo.jpg',
                '/img/logo-new/25_knoll.jpg',
                '/img/logo-new/26_utc.jpg',
                '/img/logo-new/27_s_l.jpg',
                '/img/logo-new/28_frontier.jpg',
                '/img/logo-new/29_mcs.jpg',
                '/img/logo-new/30_fundacja.jpg',
                '/img/logo-new/31_firma.jpg',
                '/img/logo-new/32_rs.jpg',
                '/img/logo-new/33_max_bud.jpg',
                '/img/logo-new/34_savi.jpg',
                '/img/logo-new/35_probitas.jpg',
                '/img/logo-new/36_czart.jpg',
                '/img/logo-new/37_incepti.jpg',
                '/img/logo-new/38_mpk.jpg']




  return (
    <>
    <h2 style={{textAlign:'center',paddingTop:'50px'}} className="subtitle is-size-4">Zaufali nam</h2>
    <div style={{paddingBottom:'50px'}}>
    <Swiper
      // install Swiper modules
      //onSwiper={(swiper) => //console.log(swiper)}
      modules={[Navigation, Pagination, A11y, Autoplay]}
      slidesPerView={3}
      spaceBetween={20}
      navigation={false}
      autoplay={true}
      loop={true}
      pagination={{ clickable: true }}
      breakpoints={{
      250: {
        slidesPerView: 1,
        spaceBetween: 20
      },

      640: {
        slidesPerView: 3,
        spaceBetween: 40
      },
      850: {
        slidesPerView: 5,
        spaceBetween: 40
      }
    }}
    //onSlideChange={() => console.log('slide change')}
    >
    {names.map((el, i, isActive) => {
     return <SwiperSlide key={el}>

     {({ isNext }) => (
     <div className="grayhover" style={{padding:'5% 0%',margin:'0 20px',textAlign:'center'}}>
       <img className={isNext ? 'natives' : 'natives-blur'} width="80%" src={photos[i]} loading="lazy" alt={el} style={{marginBottom:'0px'}} />
     </div>
     )}

     </SwiperSlide>;
   })}
   <br />
   <br />

    </Swiper>
    </div>
    </>
  );
};
