import React from "react";
import { Link } from "gatsby";

class PPostOutMain extends React.Component {
  render() {
    let posts = this.props.posts;

    console.log(posts);
    let catList = posts.filter(
      post => post.node.frontmatter.templateKey === "portfolio-page"
    );

    let filterCats = [];

    catList.forEach((item, i) => {
      // console.log(item.node.frontmatter.category)
      // console.log(filterCats.includes(item.node.frontmatter.category))
      if (!filterCats.includes(item.node.frontmatter.category)) {
        filterCats.push(item.node.frontmatter.category);
      }
    });

    console.log(filterCats);

    return (
      <>
        {filterCats.map(node => {
          console.log(node);
          return (
            <>
              <div
                style={{ width: "100%", height: "10px" }}
                id={node
                  .normalize("NFD")
                  // remove accents - diacritics - not effective for Polish letter ł
                  .replace(/[\u0300-\u036f]/g, "")
                  // letter ł
                  .replace(/['ł']/g, "l")
                  // letter ż
                  .replace(/['ż']/g, "z")
                  // change whitespace to -
                  .replace(/\s+/g, "-")
                  // remove special chars except for -
                  .replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\]/gi, "")
                  // remove emoji
                  .replace(
                    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
                    ""
                  )
                  .toLowerCase()}
              ></div>
              <div
                className="card"
                style={{
                  width: "100%",
                  margin: "50px 0px",
                  paddingBottom: "45px"
                }}
              >
                <header
                  className="card-header more"
                  style={{ alignItems: "center", backgroundColor: "#00ff00" }}
                >
                  <h2
                    className="card-header-title subtitle is-size-3"
                    style={{ margin: "10px", color: "white" }}
                  >
                    {node}
                  </h2>
                </header>
                <div
                  className="mainPosts"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center"
                  }}
                >
                  {posts
                    .filter(post => post.node.frontmatter.category === node)
                    .slice(0, 3)
                    .map(({ node }) => {
                      const title = node.frontmatter.title || node.fields.slug;
                      return (
                        <Link
                          className={`portfo pbox box ${node.frontmatter.category}`}
                          key={title}
                          to={`/${node.fields.slug}`}
                          style={{
                            margin: "3%",
                            minWidth: "300px",
                            width: "27%",
                            padding: "20px",
                            border: "1px solid #00ff00",
                            borderRadius: "10px"
                          }}
                        >
                          <div style={{ textAlign: "center" }}>
                            <img
                              style={{ borderRadius: "0px 5px", width: "100%" }}
                              alt={node.frontmatter.title}
                              loading="lazy"
                              src={node.frontmatter.image}
                            />
                          </div>
                          <div key={node.fields.slug}>
                            <h3
                              className="is-size-4"
                              style={{ minHeight: "160px" }}
                            >
                              <span style={{ boxShadow: `none` }}>{title}</span>
                              <br />
                              <sub
                                style={{
                                  backgroundColor: "#00ff00",
                                  color: "white",
                                  padding: "2px 5px"
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: node.frontmatter.category
                                }}
                              />
                            </h3>
                          </div>
                        </Link>
                      );
                    })}
                </div>
                {posts.filter(post => post.node.frontmatter.category === node)
                  .length > 3 ? (
                  <div style={{ textAlign: "center" }}>
                    <button
                      onClick={showSibling}
                      className="button standard-button pbut"
                      aria-label="load more posts"
                      style={{ margin: "20px" }}
                    >
                      Pozostałe {node.toLowerCase()}
                      <img
                        tabIndex="-1"
                        src="/img/angle-down.svg"
                        width="10px"
                        height="10px"
                        alt="more"
                        style={{
                          marginLeft: "20px",
                          marginBottom: "0px",
                          marginTop: "2.5px",
                          filter: "invert(1)",
                          transform: "rotate(90deg)"
                        }}
                      />
                    </button>
                  </div>
                ) : null}
                <div
                  className="morePosts"
                  style={{
                    display: "none",
                    flexWrap: "wrap",
                    justifyContent: "center"
                  }}
                >
                  {posts
                    .filter(post => post.node.frontmatter.category === node)
                    .slice(3)
                    .map(({ node }) => {
                      const title = node.frontmatter.title || node.fields.slug;
                      return (
                        <Link
                          className={`portfo pbox box ${node.frontmatter.category}`}
                          key={title}
                          to={`/${node.fields.slug}`}
                          style={{
                            margin: "3%",
                            minWidth: "300px",
                            width: "27%",
                            padding: "20px",
                            border: "1px solid #00ff00",
                            borderRadius: "10px"
                          }}
                        >
                          <div style={{ textAlign: "center" }}>
                            <img
                              style={{ borderRadius: "0px 5px", width: "100%" }}
                              alt={node.frontmatter.title}
                              loading="lazy"
                              src={node.frontmatter.image}
                            />
                          </div>
                          <div key={node.fields.slug}>
                            <h3
                              className="is-size-4"
                              style={{ minHeight: "160px" }}
                            >
                              <span style={{ boxShadow: `none` }}>{title}</span>
                              <br />
                              <sub
                                style={{
                                  backgroundColor: "#00ff00",
                                  color: "white",
                                  padding: "2px 5px"
                                }}
                              >
                                {node.frontmatter.date}
                              </sub>
                            </h3>
                            <br />
                            <p
                              style={{ textAlign: "center" }}
                              dangerouslySetInnerHTML={{
                                __html: node.frontmatter.category
                              }}
                            />
                          </div>
                        </Link>
                      );
                    })}
                </div>
              </div>
            </>
          );
        })}
      </>
    );
  }
}

function showSibling(e) {
  if (e.target.parentElement.nextSibling.style.display === "none") {
    e.target.parentElement.nextSibling.style.display = "flex";
    e.target.firstChild.nextSibling.nextSibling.style.transform =
      "rotate(-90deg)";
  } else {
    e.target.parentElement.nextSibling.style.display = "none";
    e.target.firstChild.nextSibling.nextSibling.style.transform =
      "rotate(90deg)";
  }
}

export default PPostOutMain;
