import React from 'react';
import { Link } from 'gatsby'


class PCatsOut extends React.Component {

    componentDidMount() {

    }

  render() {
    let cats = []
    let cimg = ['/img/cat6.jpg','/img/cat5.jpg','/img/cat4.jpg','/img/cat3.jpg','/img/cat2.jpg','/img/cat1.jpg']
     let posts = this.props.posts
    let i = -1
     posts.forEach((post) => {
       if (!cats.includes(post.node.frontmatter.category)){
         cats.push(post.node.frontmatter.category)
       }
     });

  return (
    <>
      {cats.map( cat  => {

        let catHtml = cat.replace(' ','&nbsp;<br>')
        //console.log(cat)
        switch(cat) {
          case 'Obiekty hotelowe':
            i=0
            break
          case 'Obiekty przemysłowe':
            i=1
            break
          case 'Obiekty publiczne':
            i=2
            break
          case 'Obiekty medyczne':
            i=3
            break
          case 'Obiekty biurowe':
            i=4
            break
          case 'Obiekty mieszkaniowe':
            i=5
            break
        }
        //i++
        return (
          <a className={`portfo box category`} key={cat} href={'/realizacje#'+cat.normalize("NFD")
          // remove accents - diacritics - not effective for Polish letter ł
          .replace(/[\u0300-\u036f]/g, "")
          // letter ł
          .replace(/['ł']/g, "l")
          // letter ż
          .replace(/['ż']/g, "z")
          // change whitespace to -
          .replace(/\s+/g, "-")
          // remove special chars except for -
          .replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\]/gi, '')
          // remove emoji
          .replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '')
          .toLowerCase()} style={{backgroundImage: `url(${cimg[i]})`,filter:'grayscale(1)',backgroundSize:'cover',backgroundColor:'#111',margin:'3%',minWidth:'250px',width:'27%',padding:'0px',borderRadius:'10px',minHeight:'250px',backgroundPosition:'center',display:'flex',alignItems:'flex-end'}}>
          <div key={Math.random()} style={{width:'100%'}}>
            <h3 className="is-size-6 catname"
                style={{marginBottom:'-1px',padding:'10px',color:'white',backgroundColor:'black',textTransform:'uppercase',width:'100%'}}
              dangerouslySetInnerHTML={{
                __html: catHtml,
              }} />

          </div>
          </a>
        )
      })}
    </>
  )
}
}


export default PCatsOut
