import React from "react";
import { useEffect } from "react";
import Layout from "../components/layout";
import PPostOutMain from "../components/ppostOutMain";
import PCatsOut from "../components/pcatsOut.js";
import { Link, graphql } from "gatsby";
import SEO from "../components/seo.js";
import { gsap } from "gsap";
import Newsletter from "../components/newsletter.js";
import CTA from "../components/cta.js";
import Slider3 from "../components/Slider-3.js";

// hides the items
const resetGallery = () => {
  let rest = document.getElementsByClassName("pbox");
  for (let i = 0; i < rest.length; i++) {
    rest[i].style.display = "block";
  }
};

const PortfolioPage = props => {
  const {
    pageContext: { group }
  } = props;

  useEffect(() => {
    gsap.to("#a1", { x: 500, duration: 0 });
    gsap.to("#a1", { x: 0, duration: 5 });
    gsap.to("#a2", { x: -500, y: 35, duration: 0 });
    gsap.to("#a2", { x: 50, duration: 5, delay: 1 });
  }, []);

  let posts = group;
  //console.log(posts)
  posts = posts.filter(post => post.node.frontmatter.language === "Polish");

  return (
    <>
      <Layout>
        <SEO title="Realizacje" />
        <div className="x-wrapper">
          <div
            className="columns"
            style={{
              minHeight: "500px",
              padding: "0px",
              margin: "0px",
              alignItems: "center",
              backgroundColor: "white"
            }}
          >
            <div
              id="lcol"
              className="column nopad"
              style={{
                justifyContent: "center",
                padding: "2% 0%",
                maxWidth: "25%"
              }}
            >
              <div className="nopadleft" style={{ paddingLeft: "3rem" }}>
                <h1
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2em",
                    marginTop: "30px"
                  }}
                  className="title"
                >
                  <strong style={{ color: "#00ff00" }}>REALIZACJE </strong>
                  <br />
                  <span style={{ fontSize: ".5em" }}>
                    {" "}
                    Zobacz efekty <br /> naszej pracy
                  </span>
                </h1>
                <hr
                  style={{
                    maxWidth: "150px",
                    backgroundColor: "#333333",
                    height: "3px"
                  }}
                />
                <div
                  className="show-mob"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <img
                    id="mobile-x"
                    src="/img/x.svg"
                    style={{
                      marginBottom: "20px",
                      maxWidth: "500px",
                      marginTop: "-75px"
                    }}
                    width="auto"
                    height="auto"
                    alt="Rexer engineering"
                  />
                </div>
                <p>
                  <strong>
                    Zapraszamy do zapoznania się ze szczegółami naszych
                    realizacji
                  </strong>
                  , obejrzenia zdjęć oraz sprawdzenia lokazliacji poszczególnych
                  obiektów.
                </p>
                <a href="#main" className="button rexer-button">
                  Sprawdź rodzaje obiektów
                </a>
              </div>
              <br />
              <div
                className="column mob-hide is-two-thirds"
                style={{ position: "relative" }}
              >
                <img
                  id="a2"
                  style={{
                    marginTop: "0px",
                    marginBottom: "-30%",
                    marginLeft: "-20px",
                    maxWidth: "550px",
                    opacity: "1"
                  }}
                  src="/img/r1.png"
                  width="auto"
                  alt="animacja-kula"
                />
              </div>
            </div>

            <div
              id="midcol"
              className="column"
              style={{ width: "50%", marginTop: "-100px", textAlign: "center" }}
            >
              <img
                src="/img/x.svg"
                style={{ marginBottom: "0" }}
                width="auto"
                height="auto"
                alt="Rexer engineering"
              />
              <p
                id="paddmob"
                style={{ textAlign: "center", marginTop: "-20px" }}
              >
                <a
                  href="#main"
                  style={{ border: "0px solid white", marginTop: "0px" }}
                  className="button standard-button"
                >
                  ↓
                </a>
              </p>
            </div>

            <div
              id="rcol"
              className="column"
              style={{
                maxWidth: "25%",
                margin: "0px",
                marginTop: "-175px",
                padding: "0px",
                alignItems: "end",
                display: "block",
                justifyContent: "center",
                marginRight: "3em"
              }}
            >
              <div style={{ textAlign: "right", width: "100%" }}>
                <img
                  id="a1"
                  src="/img/of1.png"
                  width="100%"
                  height="auto"
                  alt="Rexer engineering"
                  style={{
                    minWidth: "300px",
                    maxWidth: "400px",
                    opacity: "1",
                    marginBottom: "0px",
                    borderBottom: "3px solid #f6f6f6",
                    backgroundImage: "url(/img/hero-bg-home.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                  }}
                />
              </div>
              <div
                className="deco-w"
                style={{
                  border: "5px solid #333333",
                  padding: "20px",
                  marginTop: "3em",
                  marginLeft: "1rem"
                }}
              >
                <p
                  className="deco"
                  style={{ textAlign: "right", backgroundColor: "white" }}
                >
                  Naszymi klientami są <b>inwestorzy</b> jak i{" "}
                  <b>generalni wykonawcy</b>. Wspólpracujemy z tymi małymi i
                  tymi największymi.
                </p>
              </div>
              <p
                id="marginmob"
                style={{ textAlign: "right", marginTop: "3em" }}
              >
                <a href="#trusted" className="button rexer-button">
                  Sprawdź kto nam zaufał
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          id="main"
          className="is-bold"
          style={{
            textAlign: "left",
            backgroundColor: "#f6f6f6",
            padding: "5%",
            position: "relative",
            zIndex: "1"
          }}
        >
          <h2
            className="subtitle is-size-5"
            style={{
              borderLeft: "5px solid #00ff00",
              marginTop: "50px",
              paddingLeft: "10px",
              marginLeft: "-10px"
            }}
          >
            <strong style={{ textTransform: "uppercase" }}>
              BUDYNKI BIUROWE, HALE PRODUKCYJNE, OBIEKTY MEDYCZNE, HOTELOWE ORAZ
              INNE RODZAJE OBIEKTÓW W NASZYM PORTFOLIO!
            </strong>
          </h2>
          <br />
          <p className="is-size-5" style={{ fontWeight: "400" }}>
            <strong>Setki</strong> kilogramów czynnika chłodniczego,{" "}
            <strong>tysiące</strong> kilometrów instalacji rurowych,{" "}
            <strong>dziesiątki tysięcy</strong> metrów kwadratowych kanałów
            wentylacyjnych -to najnowocześniejsze rozwiązania technologiczne i
            inżynieryjne w praktyce. Dowiedz się więcej o każdej z naszych
            realizacji i o przeprowadzonych w tym zakresie{" "}
            <strong>prac inżynieryjnych</strong>.
          </p>
        </div>
        <div
          id="scroll-wrapper"
          className="home-cats"
          style={{
            overflowX: "scroll",
            backgroundColor: "#00ff00",
            display: "flex",
            flexWrap: "no-wrap",
            position: "relative",
            zIndex: "2"
          }}
        >
          <PCatsOut posts={posts} />
        </div>
        <div id="trusted" style={{ width: "100%", backgroundColor: "white" }}>
          <Slider3 />
        </div>

        {/* <iframe id="bpar" title="particles" src="/particles-black.html" loading="lazy" frameBorder="0" style={{width:'100%',height:'450px',position:'relative',zIndex:'1'}}></iframe>
     <div id="relalizacje" style={{padding:'2.5% 5%',height:'450px',marginTop:'-450px'}}>
     <Link  to="/realizacje/"><h2 className="title is-size-3" style={{position:'relative',zIndex:'2'}} >Kategorie</h2></Link>
     <h3 className="subtitle is-size-4" style={{position:'relative',zIndex:'2'}}>Kategorie naszych realizacji.</h3>
       <div id="scroll-wrapper" style={{overflowX:'scroll',display:'flex',flexWrap:'no-wrap',position:'relative',zIndex:'2'}}>
       <PCatsOut posts={posts} />
       </div>
      </div>
      <br id="details" />
      <div style={{textAlign:'center',marginTop:'3%'}}>
      <div className="select is-medium is-dark">
      <select className="standard-button" style={{borderRadius:'0',backgroundColor:'rgba(0,0,0,0)'}} id="catlist" onChange={filterGallery}>
        <option value="wszystkie realizacje" >Wszystkie realizacje</option>
      </select>
      </div>
      </div> */}

        <div className="nopad" style={{ padding: "3em", marginTop: "-2em" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center"
            }}
          >
            <PPostOutMain posts={group} />
          </div>
          {/*<div className='buttons is-centered' style={{marginTop:'25px'}}>
        <PaginationLink test={first} url={previousUrl} text='<' />
        <PaginationLink test={last} url={nextUrl} text='>' />
      </div>*/}
        </div>

        <Newsletter />
        <CTA />
      </Layout>
    </>
  );
};

export default PortfolioPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD. MM. YYYY")
            title
            description
            language
            category
            templateKey
            image
          }
        }
      }
    }
  }
`;
